import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
  Radio,
  RadioGroup,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import MaskedInput from 'react-text-mask';
import { getTokenEvergreenAPI } from './authService';

export function CustomModal({
  open,
  onClose,
  user,
  formData: propFormData,
  onFilePosted 
}) {
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [filesDropped, setFilesDropped] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingFileDetails, setLoadingFileDetails] = useState(false);
  const [selectedSystemDocumentName, setSelectedSystemDocumentName] = useState('');
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [systems, setSystems] = useState([]);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [manualEntrySelected, setManualEntrySelected] = useState(true);

  const handleRadioChange = (event) => {
    setManualEntrySelected(event.target.value === 'manual');

    // Clear errors if the 'Viðtakandi' field is empty
    if (event.target.value === 'manual' && !localFormData.nameOfOwner.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        nameOfOwner: '',
      }));
    }

    // Clear errors if the 'Titill' field is empty
    if (event.target.value === 'manual' && !localFormData.name.trim()) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: '',
      }));
    }
    // Clear dropped files state when switching radio buttons
    setFilesDropped(false);
    setDroppedFiles([]); // Clear dropped files
    setErrorMessage('');
    // Reset the value of systemCaseId to null when switching radio buttons
    setLocalFormData((prevFormData) => ({
      ...prevFormData,
      systemCaseId: null,
      documentBytes: null,
      systemDocumentId: null,
      selectedSystemDocumentId: null,
      fileEnding: '',
      fileType: '',
      name: '',
      systemId: 1,
      systemDocumentNames: [],
      systemDocumentName: '',
      webuser: user.account.username,
    }));
  };
  // Add this line to define setFormData
  const [localFormData, setLocalFormData] = useState({
    nameOfOwner: '', // Add new fields here
    sendNotification: false,
    name: '',
    systemDocumentId: null,
    systemCaseId: null,
    systemDocumentName: '',
    systemDocumentNames: [], // Array to store multiple systemDocumentNames
    category : '',
    type : '',
    categoryId: 1,
    typeId: 1,        
    selectedSystemDocumentId: null,
    documentBytes: null,
    documentTypeId: 1,  
    systemUserId: "2",
    systemId: 1,    
    publicationDate: dayjs(),
    documentDate: dayjs(),
    fileEnding: '',
    fileType: '',
    webuser: user.account.username,
    });

  // Add this state variable at the beginning of your component
  const [formErrors, setFormErrors] = useState({
    name: '',
    nameOfOwner: '',
    type: '',
    category: '',
    system: '',
    documentBytes: null
  });

  // Add a state variable to track loading state
  const [submitting, setSubmitting] = useState(false);

  const [kennitalaFocused, setKennitalaFocused] = useState(false);

  const handleKennitalaFocus = () => {
    setKennitalaFocused(true);
  };

  const handleKennitalaBlur = () => {
    setKennitalaFocused(false);
  };
  const fetchCategories = async () => {
    try {
      let token = await getTokenEvergreenAPI();      
      const config = {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`         
        },
      }; 

      const response = await fetch(`${apiBaseUrl}/api/v1/notification/getcategories`, config);
  
      if (response.ok) {
        const data = await response.json();

        setCategories(data); // Update the categories state
      } else {
        console.error('Error:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching Categories:', error.message);
    }
  };
  
  const fetchTypes = async () => {
    try {

      let token = await getTokenEvergreenAPI();      
      const config = {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`         
        },
      }; 

      const response = await fetch(`${apiBaseUrl}/api/v1/notification/gettypes`, config);

    if (response.ok) {
      const data = await response.json();
      setTypes(data); // Update the types state
    } else {
      console.error('Error:', response.status, response.statusText);
    }

    } catch (error) {
      console.error('Error fetching Types:', error.message);
    }
  };

  const fetchSystems = async () => {
    try {

      let token = await getTokenEvergreenAPI();      
      const config = {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`         
        },
      }; 

      const response = await fetch(`${apiBaseUrl}/api/v1/general/getsystems`, config);
    if (response.ok) {
      const data = await response.json();
      setSystems(data); // Update the systems state
    } else {
      console.error('Error:', response.status, response.statusText);
    }

    } catch (error) {
      console.error('Error fetching Systems:', error.message);
    }
  };

  const fetchData = async () => {
    try {
      let token = await getTokenEvergreenAPI();      
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };        
      
      // Make the fetch request using the updated ID
      const idskjals = localFormData.selectedSystemDocumentId;
      const response = await fetch(`${apiBaseUrl}/api/v1/OneSystem/getonebytes?documentId=${idskjals}`, config);
      if (!response.ok) {
        throw new Error(`Failed to fetch One system document. Status: ${response.status}`);
      }

      const fileDetailsArray = await response.json();

      // Check if 'skjal' property exists in the first object of the array
      if (fileDetailsArray && fileDetailsArray.length > 0 && fileDetailsArray[0].file !== undefined) {
        const firstFileDetails = fileDetailsArray[0];

        // Update state with the file details including bytes
        setLocalFormData((prevFormData) => ({
          ...prevFormData,
          documentBytes: firstFileDetails.file, // Assuming the response contains the 'skjal' field
          fileEnding: firstFileDetails.type, // Assuming the response contains the 'tegund' field
          fileType: firstFileDetails.type, // Assuming the response contains the 'tegund' field
          systemDocumentId: firstFileDetails.oneSystemId,
        }));
        setErrorMessage('');
      } else {
        console.error('Error: "skjal" property not found in the first object of the API response:', fileDetailsArray);
      }
    } catch (error) {
      console.error('Error fetching One system document:', error.message);
    }
  };    
  

  const handleInputChange = async (event) => {
    const { name, value, type, checked } = event.target;
    // Check if the input is the 'Kennitala' field
    if (name === 'Kennitala') {
      // Remove hyphen and only keep digits
      const cleanedValue = value.replace(/[^0-9]/g, '');
  
      // Check if the cleaned value has a length of 10
      if (cleanedValue.length === 10) {
        try {

          let token = await getTokenEvergreenAPI();      
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }; 

          // Make an API request to get user details by ID
          const response = await fetch(`${apiBaseUrl}/api/v1/user/getnationalregistry?kennitala=${cleanedValue}`, config);
          if (!response.ok) {
            throw new Error(`Failed to fetch user details. Status: ${response.status}`);
          }
  
          const userData = await response.json();
  
          // Update state with the user's name
          setLocalFormData((prevFormData) => ({
            ...prevFormData,
            nameOfOwner: userData.name, // Assuming the user's name is available in the response
            [name]: cleanedValue,
          }));
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            nameOfOwner: '',
          }));
        } catch (error) {
          setErrorMessage('Kennitala fannst ekki');
          // Update state with the user's name
          setLocalFormData((prevFormData) => ({
            ...prevFormData,
            [name]: cleanedValue,
          }));
          console.error('Error fetching user details:', error.message);
          // Handle error (e.g., show an error message)
        }
      } else {
        // Clear the 'viðtakandi' field if the length is not 10
        setErrorMessage('');
        setLocalFormData((prevFormData) => ({
          ...prevFormData,
          nameOfOwner: '',
          [name]: cleanedValue,  // Optionally, you may keep the 'kennitala' value
        }));
      }
    }
    else if (name === 'name') {
      // Clear the error message when 'Titill' is changed
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
  
      // Update the local form data
      setLocalFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    else if (name === 'systemCaseId') {
      // For Málsnúmer, call the fetchFileDetails function      
      // Update the localFormData with the selected systemCaseId
      setLocalFormData((prevFormData) => ({
        ...prevFormData,
        systemCaseId: value.trim(), // Trim the value to remove leading/trailing spaces
        systemDocumentNames: [...prevFormData.systemDocumentNames, value]
      }));
    }
    
    else if (name === 'systemDocumentName') {
      // Find the selected systemDocument in the localFormData array
      const selectedSystemDocument = localFormData.systemDocumentNames.find(
        (document) => document.name === value
      );
    
      // Update the selectedSystemDocumentId state with the corresponding oneSystemId
      setLocalFormData((prevFormData) => ({
        ...prevFormData,
        selectedSystemDocumentId: selectedSystemDocument ? selectedSystemDocument.oneSystemId : null,
        systemDocumentNames: [...prevFormData.systemDocumentNames, value],
        name: value, // Update Viðtakandi with the chosen name
      }));
        // Set the selected value for the dropdown
        setSelectedSystemDocumentName(value);
    }
    else if (name === 'category' || name === 'type' || name === 'system') {
      // Clear the error message when a value is chosen from the dropdown
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
      if(name === 'category'){
        // Find the selected category in the categories array
        const selectedCategory = categories.find((category) => category.name === value);
        // Update localFormData for category
        setLocalFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
          CategoryId: selectedCategory ? selectedCategory.nr : null,        
          Category: selectedCategory ? selectedCategory.name : null,        
        }));
      } else if (name === 'type'){
        // Find the selected type in the types array
        const selectedType = types.find((type) => type.name === value);
        // Update localFormData for type
        setLocalFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
          TypeId: selectedType ? selectedType.nr : null,
          Type: selectedType ? selectedType.name : null,
        }));
      }
      else if (name === 'system'){
        // Find the selected system in the systems array
        const selectedSystem = systems.find((system) => system.description === value);
        // Update localFormData for systems
        setLocalFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
          SystemId: selectedSystem ? selectedSystem.id : null,
          System: selectedSystem ? selectedSystem.description : null,
        }));
      }
    }
    
    else {
      setLocalFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };
  
  // Add this function to handle the drop event
  const handleDrop = (e) => {
    e.preventDefault();
  
    // Check if there are dropped files
    if (e.dataTransfer.items) {
      const pdfFiles = Array.from(e.dataTransfer.items)
        .filter((item) => item.kind === 'file' && item.type === 'application/pdf')
        .map((item) => item.getAsFile());
  
      // Update state with dropped PDF files
      setDroppedFiles(pdfFiles);
  
      setFilesDropped(true); // Set the filesDropped state to true

      // Read the content of the first dropped PDF file as base64
      if (pdfFiles.length > 0) {
        const pdfFile = pdfFiles[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          // Update the documentBytes field in local form data
          setLocalFormData((prevFormData) => ({
            ...prevFormData,
            documentBytes: reader.result.split(';base64,').pop(),
          }));
        };
        setErrorMessage('');
        reader.readAsDataURL(pdfFile);
      }
    }
  };
  
  

  const handleFileInput = (e) => {
    const files = e.target.files;
  
    // Check if there are selected files
    if (files.length > 0) {
      const pdfFiles = Array.from(files).filter((file) => file.type === 'application/pdf');
  
      if (pdfFiles.length > 0) {
        const pdfFile = pdfFiles[0];
  
        // Read the content of the PDF file as base64
        const reader = new FileReader();
        reader.onloadend = () => {
          setLocalFormData((prevFormData) => ({
            
            ...prevFormData,
            documentBytes: reader.result.split(';base64,').pop(), // Base64 representation of the PDF file
            fileEnding: 'pdf',
            fileType: 'pdf',
          }));
        };
        reader.readAsDataURL(pdfFile);
      } else {
        // Handle invalid file type or no PDF file selected
        setLocalFormData((prevFormData) => ({
          ...prevFormData,
          documentBytes: null,
          fileEnding: '',
          fileType: '',
        }));
      }
      // Update state with dropped PDF files
      setDroppedFiles(pdfFiles);
      setFilesDropped(true); // Set the filesDropped state to true
      setErrorMessage('');
    }
  };

  const fetchFileDetails = async (systemCaseId, kennitala) => {
    setLoadingFileDetails(true);
    try {

      // Clear the systemDocumentNames list before making the fetch request
      setLocalFormData((prevFormData) => ({
        ...prevFormData,
        systemDocumentNames: [],
        name: "",
      }));

      let token = await getTokenEvergreenAPI();      
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }; 

      const response = await fetch(`${apiBaseUrl}/api/v1/OneSystem/getonefiles?oneSystemsCaseId=${systemCaseId}&kennitala=${kennitala}`, config);
      localFormData.systemCaseId.trim();
      if (!response.ok) {
        if (response.status === 204) {
          setErrorMessage('Málsnúmer finnst ekki!');
          throw new Error('Málsnúmer finnst ekki!');
        } else if (response.status === 403) {
          setErrorMessage('Þetta málsnúmer er ekki tengt kennitölunni hér að neðan!');
          throw new Error('Þetta málsnúmer er ekki tengt kennitölunni hér að neðan');
        } else {
          setErrorMessage('Villa kom upp við að sækja skjöl!');
          throw new Error(`Failed to fetch file details. Status: ${response.status}`);
        }
      }
  
      const fileData = await response.json();
  
      // Assuming fileData is an array of objects with "name" and "oneSystemId" fields
      const updatedSystemDocumentNames = fileData.map(({ name, oneSystemId }) => ({
        name,
        oneSystemId,
      }));
  
      // Update state with the file details
      setLocalFormData((prevFormData) => ({
        ...prevFormData,
        systemDocumentNames: [...prevFormData.systemDocumentNames, ...updatedSystemDocumentNames],
      }), () => {
      });
  
    } catch (error) {
      console.error('Error fetching file details:', error.message);
    } finally {
      setLoadingFileDetails(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (submitting) {
      return;
    }
  
    setSubmitting(true);
  
    const errors = {};
  
    if (!localFormData.nameOfOwner.trim()) {
      errors.nameOfOwner = 'Viðtakandi er nauðsynlegur!';
    }
  
    if (!localFormData.category) {
      errors.category = 'Category is required';
    }
  
    if (!localFormData.type) {
      errors.type = 'Type is required';
    }
    if (!localFormData.system) {
      errors.system = 'System is required';
    }
  
    if (manualEntrySelected) {
      if (droppedFiles.length === 0) {
        setErrorMessage('Engin skrá valin handvirkt!');
      }
      if (!localFormData.name.trim()) {
        errors.name = 'Titill er nauðsynlegur!';
      }
    } else {
      if (!localFormData.name.trim()) {
        setErrorMessage('Engin skrá úr OneSystem valin!');
      }
    }
  
    if (Object.keys(errors).length === 0) {
      try {
        if (!localFormData.documentBytes) {
          errors.name = 'Titill is required';
          throw new Error('Please select a PDF file.');
        }
  
        let token = await getTokenEvergreenAPI();
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };
  
        const trimmedSystemCaseId = localFormData.systemCaseId ? localFormData.systemCaseId.trim() : null;
        setLocalFormData((prevFormData) => ({
          ...prevFormData,
          systemCaseId: trimmedSystemCaseId,
        }));
        const response = await fetch(`${apiBaseUrl}/api/v1/Document/createdocument`, {
          method: 'POST',
          ...config,
          body: JSON.stringify(localFormData),
        });
  
        if (!response.ok) {
          const errorResponse = await response.json();
          console.error('Error response:', errorResponse);
          throw new Error('Error submitting form data');
        }
  
        onClose();
        onFilePosted();
        setDroppedFiles([]);
      } catch (error) {
        console.error('Error submitting form data:', error.message);
      } finally {
        setSubmitting(false);
      }
    } else {
      setFormErrors(errors);
      setSubmitting(false);
      return;
    }
  };
  
  
  // useEffect hook to handle the asynchronous API call
  useEffect(() => {
    fetchCategories();
    fetchTypes();
    fetchSystems();
    // Check if selectedSystemDocumentId is not null before making the API call
    if (localFormData.selectedSystemDocumentId !== null) {
      fetchData();
    }
  }, [localFormData.selectedSystemDocumentId]);


    
  
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
      <div>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            // Add maxHeight and overflowY styles
            maxHeight: '100vh', // Adjust the value based on your needs
            overflowY: 'auto',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            handleDrop(e);
          }}
        >
          <Typography variant="h5" gutterBottom>
            Setja inn nýtt skjal
          </Typography>
          {/* Radio buttons */}
          <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
            <RadioGroup
              row
              aria-label="entry-method"
              name="entry-method"
              value={manualEntrySelected ? 'manual' : 'oneSystem'}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="manual"
                control={<Radio color="primary" />}
                label="Setja inn handvirkt"
              />
              <FormControlLabel
                value="oneSystem"
                control={<Radio color="primary" />}
                label="Sækja í OneSystem"
              />
            </RadioGroup>
          </FormControl>
          <Box
          sx={{
            border: '2px dashed #aaaaaa',
            padding: '60px',
            backgroundColor: '#f0f0f0',
            cursor: 'pointer',
            display: manualEntrySelected ? 'block' : 'none', // Hide for OneSystem entry
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            handleDrop(e);
          }}
          onClick={() => {
            // Trigger the file input when the indicator is clicked
            document.getElementById('fileInput').click();
          }}
        >
            <Typography variant="body1" color="textSecondary">
              Dragðu skjal/skjöl inn eða smelltu hér til að velja skjöl. Einungis PDF skjöl leyfð.
            </Typography>
            {/* Hidden file input for opening the file window */}
            <input
              id="fileInput"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileInput}
              accept=".pdf"
            />
          </Box>
          {filesDropped && (
            <Box sx={{ marginTop: '20px'}}>
              <Typography variant="h6" gutterBottom>
                Skjöl:
              </Typography>
              <ul>
                {droppedFiles.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            </Box>
          )}
          {errorMessage && (
          <Typography variant="body2" color="error" gutterBottom>
            {errorMessage}
          </Typography>
        )}
          <form onSubmit={handleSubmit}>
          <TextField
          label="Kennitala"
          variant="outlined"
          fullWidth
          margin="normal"
          name="Kennitala"
          value={localFormData.Kennitala}
          onChange={handleInputChange}
          onFocus={handleKennitalaFocus}
          onBlur={handleKennitalaBlur}
          error={!!formErrors.Kennitala}
          helperText={formErrors.Kennitala}
          InputProps={{
            inputComponent: KennitalaMask, // Custom input component for formatting
            kennitalaFocused, // Pass kennitalaFocused as a prop
          }}
        />
            <TextField
              label="Viðtakandi"
              variant="outlined"
              fullWidth
              margin="normal"
              name="nameOfOwner"
              disabled
              value={localFormData.nameOfOwner}
              onChange={handleInputChange}
              error={!!formErrors.nameOfOwner}
              helperText={formErrors.nameOfOwner}
            />
            <FormControl fullWidth >
            <FormControlLabel
              control={
                <Checkbox
                  checked={localFormData.sendNotification}
                  onChange={handleInputChange}
                  name="sendNotification"
                />
              }
              label="Senda tilkynningu á viðtakanda(Hnipp)"
              style={{ marginTop: '20px' }}
            />
            </FormControl>
            {/* Fields for manual entry */}
          {manualEntrySelected && (
            <div>
              <TextField
                label="Titill"
                variant="outlined"
                fullWidth
                margin="normal"
                name="name"
                value={localFormData.name}
                onChange={handleInputChange}
                error={!!formErrors.name}
                helperText={formErrors.name}
              />
              <FormControl fullWidth style={{ marginTop: '20px' }}>
              <InputLabel id="types-label">Veldu kerfi</InputLabel>
              <Select
                labelId="types-label"
                label="Select Type"
                variant="outlined"
                name="system"
                value={localFormData.system !== undefined ? localFormData.system : ''}
                onChange={(event) => handleInputChange(event)} 
                style={{ textAlign: 'left' }}
                error={!!formErrors.system}
                helperText={formErrors.system}
              >
                {systems.map((system, index) => (
                  <MenuItem key={index} value={system.description}>
                    {system.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </div>
          )}
          {/* Fields for One systems entry */}
          {!manualEntrySelected && (
          <div><TextField
              label="Málsnúmer"
              variant="outlined"
              fullWidth
              margin="normal"
              name="systemCaseId"
              value={localFormData.systemCaseId}
              onChange={handleInputChange}
              error={!!formErrors.systemCaseId}
              helperText={formErrors.systemCaseId}
              onFocus={() => setErrorMessage('')} 
            />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
              onClick={() => {
                // Check if the systemCaseId is not null and not empty before making the API call
                if (localFormData.systemCaseId && localFormData.systemCaseId.trim() !== '') {
                  fetchFileDetails(localFormData.systemCaseId, localFormData.Kennitala);
                } else {
                  setErrorMessage('Málsnúmer verður að vera fyllt inn!');
                }
              }}
              disabled={submitting}
            >
              Sækja í One
            </Button>
            {loadingFileDetails && (
              <CircularProgress size={20} style={{ marginLeft: '10px' }} />
            )}
          </div>
            <FormControl fullWidth style={{ marginTop: '20px' }}>
            <InputLabel id="systemDocumentName-label">Skjal úr One</InputLabel>
            <Select
              labelId="systemDocumentName-label"
              label="Veldu skjal úr One Systems"
              variant="outlined"
              name="systemDocumentName"
              value={selectedSystemDocumentName}
              onChange={handleInputChange}
              error={!!formErrors.systemDocumentName}
              disabled={loadingFileDetails}
              style={{ marginTop: '10px', textAlign: 'left' }}
            >
              {localFormData.systemDocumentNames.map((systemDocument) => (
                <MenuItem
                  key={systemDocument.oneSystemId}
                  value={systemDocument.name}
                  onClick={() => {
                    handleInputChange({
                      target: { name: 'systemDocumentName', value: systemDocument.name },
                    });
                  }}
                >
                  {systemDocument.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </div>
          )}       
          <FormControl fullWidth style={{ marginTop: '20px' }}>
          <InputLabel id="categories-label">Flokkur</InputLabel>
          <Select
            labelId="categories-label"
            label="Select Category"
            variant="outlined"
            name="category"
            value={localFormData.category !== undefined ? localFormData.category : ''}
            onChange={(event) => handleInputChange(event)} 
            style={{ textAlign: 'left' }}
            error={!!formErrors.category}
            helperText={formErrors.category}
          >
            {categories.map((category, index) => (
              <MenuItem key={index} value={category.name}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginTop: '20px' }}>
        <InputLabel id="types-label">Hópur</InputLabel>
        <Select
          labelId="types-label"
          label="Select Type"
          variant="outlined"
          name="type"
          value={localFormData.type !== undefined ? localFormData.type : ''}
          onChange={(event) => handleInputChange(event)} 
          style={{ textAlign: 'left' }}
          error={!!formErrors.type}
          helperText={formErrors.type}
        >
          {types.map((type, index) => (
            <MenuItem key={index} value={type.name}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
            <FormControl fullWidth style={{ marginTop: '20px'}}>
            <DateTimePicker
              label="Birta skjal þann"
              minDate={dayjs()} 
              value={localFormData.publicationDate || dayjs()}
              onChange={(publicationDate) => handleInputChange({ target: { name: 'publicationDate', value: publicationDate } })}

            />
            </FormControl>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
            {submitting && <CircularProgress size={40} style={{ marginBottom: '20px' }} />} {/* Render loading spinner when submitting */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
              disabled={submitting}
            >
              Setja inn
            </Button>
          </div>
          </form>
        </Box>
      </div>
    </Modal>
  );
}

  const KennitalaMask = React.forwardRef(({ value = '', kennitalaFocused, ...props }, ref) => {
  const { inputRef, ...other } = props;

  // Function to format 'kennitala' value with a hyphen after the 6th digit
  const formatKennitala = (value) => {
    if (value) {
      return value.replace(/(\d{6})(\d{1,4})/, '$1-$2');
    }
    return '';
  };

  // Display the mask characters based on the kennitalaFocused state
  const displayMask = kennitalaFocused ? 'dddddd-dddd' : 'dddddd-dddd';

  // Modify this line to use inputRef
  return (
    <MaskedInput
      {...other}
      ref={ref} // Pass the ref to the MaskedInput
      inputRef={inputRef} // Pass the inputRef directly to the MaskedInput
      mask={displayMask.split('').map((char) => (char === 'd' ? /\d/ : char))}
      placeholderChar={'\u2000'} // Use a non-breaking space as a placeholder character
      value={formatKennitala(value)}
    />
  );
});