import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Container,
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import Navbar from './Navbar';
import { DataTable } from './DataTable';
import { CustomModal } from './CustomModal';
import { useUser } from './UserContext';
import axios from 'axios';
import { getTokenEvergreenAPI } from './authService';
import Swal from 'sweetalert2';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

function App() {
  const { user, login } = useUser();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [openModal, setOpenModal] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [dateFrom, setDateFrom] = useState(dayjs().subtract(5, 'days'));
  const [dateTo, setDateTo] = useState(dayjs().add(1, 'days'));
  const [system, setSystem] = useState(1); // Changed to an empty string initially
  const [systems, setSystems] = useState([]); // Initialize systems as an empty array
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      login();
    } else {
      fetchSystems();
      fetchData(); // Fetch data initially when user is available
    }
  }, [user]);

  const fetchSystems = async () => {
    try {
      const token = await getTokenEvergreenAPI();
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(`${apiBaseUrl}/api/v1/general/getsystems`, config);

      if (response.ok) {
        const data = await response.json();
        if (Array.isArray(data)) {
          setSystems(data); // Update the systems state only if it's an array
        } else {
          console.error('Unexpected response format:', data);
        }
      } else {
        console.error('Error:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching Systems:', error.message);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = await getTokenEvergreenAPI();
      const configGetDocuments = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${apiBaseUrl}/api/v1/Document/getdocuments`, {
        params: {
          dateFrom: dateFrom.format('YYYY-MM-DD HH:mm:ss'),
          dateTo: dateTo.format('YYYY-MM-DD HH:mm:ss'),
          system: system === '' ? null : system, // Handle empty system
        },
        ...configGetDocuments,
      });
      setApiData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Villa við að sækja gögn',
        text: 'Ekki tókst að sækja gögn, vinsamlegast reyndu aftur síðar.',
      });
    }
    setLoading(false);
  };

  const handleFilePosted = async () => {
    try {
      await fetchData();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleFileDeleted = async () => {
    try {
      await fetchData();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleOpenModal = () => {
    if (user.isWrite || user.isAdmin) {
      setOpenModal(true);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Aðgerð ekki leyfð...',
        text: 'Notandi hefur ekki réttindi til að bæta við skjölum!',
      });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFetchClick = () => {
    fetchData();
  };

  return (
    <div>
      <Navbar onFilePosted={handleFilePosted} />
      {user ? (
        <Container className="homepage-container" style={{ marginTop: '40px', maxWidth: '2200px' }}>
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            style={{
              fontSize: '70px',
              fontWeight: '600',
              backgroundImage: 'linear-gradient(to left, #9AB8DF, #122339)',
              color: 'transparent',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              fontFamily: 'Setimo, sans-serif',
            }}
          >
            Skjalaveita
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Box display="flex" alignItems="center">
              <DateTimePicker
                label="Frá"
                value={dateFrom}
                onChange={(newValue) => setDateFrom(newValue)}
                renderInput={(params) => <TextField {...params} />}
                disabled={loading}
              />
              <DateTimePicker
                label="Til"
                value={dateTo}
                onChange={(newValue) => setDateTo(newValue)}
                renderInput={(params) => <TextField {...params} />}
                sx={{ marginLeft: 2 }}
                disabled={loading}
              />
              <FormControl sx={{ marginLeft: 2 }}>
                <InputLabel id="system-label">Kerfi</InputLabel>
                <Select
                  labelId="system-label"
                  value={system}
                  onChange={(e) => setSystem(e.target.value)} // Handle system state correctly
                  label="Kerfi"
                  disabled={loading}
                >
                  {systems.map((sys, index) => (
                    <MenuItem key={index} value={sys.id}>
                      {sys.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFetchClick}
                sx={{ textTransform: 'none', width: '150px', marginLeft: 2 }}
                disabled={loading}
              >
                Sækja
              </Button>
              {loading && <CircularProgress size={24} sx={{ marginLeft: 2 }} />}
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              sx={{ textTransform: 'none', width: '200px' }}
            >
              Bæta við skjali
            </Button>
          </Box>
          <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-title">
            <div>
              <CustomModal open={openModal} onClose={handleCloseModal} onFilePosted={handleFilePosted} user={user} />
            </div>
          </Modal>
          <DataTable data={apiData} onFileDeleted={handleFileDeleted} user={user} />
        </Container>
      ) : (
        <Container className="login-message-container" style={{ marginTop: '40px', maxWidth: '2400px' }}>
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            style={{
              fontSize: '30px',
              fontWeight: '600',
              color: '#333',
            }}
          >
            Vinsamlegast skráðu þig inn.
          </Typography>
        </Container>
      )}
    </div>
  );
}

export default App;
